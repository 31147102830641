import React from 'react';
import styled from 'styled-components';
import { scroller } from 'react-scroll';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: var(--background-color);
  border-radius: 20px;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.35);
  overflow: hidden;
  min-height: 1447px;

  ul {
    margin-left: 1em;
  }

  .serviceCardHeader {
    width: 100%;
    height: 10em;
    background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
      url(${(props) => props.image}) center center no-repeat;
    background-size: cover;
    object-fit: scale-down;
  }

  .serviceOptionTitle {
    font-family: 'IBM Plex Serif', serif;
    font-size: 1.4em;
    font-weight: 600;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    padding: 0.3em;
  }

  .cardDetails {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 1em;
    height: 100%;
  }

  .serviceOptionDescription {
    margin-top: 0.2em;
    margin-bottom: 2em;
    font-size: 0.75em;
    font-weight: 400;
  }
`;

const ServicesCard = ({ children, image, imageAlt, title }) => (
  <Container image={image} role="img" aria-label={`[${imageAlt}]`}>
    <div className="serviceCardHeader">
      <p className="serviceOptionTitle">{title}</p>
    </div>
    <div className="cardDetails">
      <div className="serviceOptionDescription">{children}</div>
      <button
        onClick={() =>
          scroller.scrollTo('contact', {
            spy: true,
            smooth: true,
            offset: -250,
            duration: 500,
          })
        }
      >
        Get a quote
      </button>
    </div>
  </Container>
);

export default ServicesCard;
