import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import BlogCard from '../blog/BlogCard';

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  height: auto;
  margin: 1em 0;
`;

export default (props) => (
  <StaticQuery
    query={graphql`
      query {
        allMdx(sort: { fields: [frontmatter___date], order: DESC }, limit: 3) {
          edges {
            node {
              frontmatter {
                title
                slug
                category
                date(formatString: "MMMM DD, YYYY")
                excerpt
                featuredImage {
                  childImageSharp {
                    fluid(quality: 100) {
                      src
                    }
                  }
                }
                featuredImageAlt
              }
            }
          }
        }
      }
    `}
    render={(data) => <SectionBlog data={data} {...props} />}
  />
);

const SectionBlog = ({ data }) => {
  const posts = data.allMdx.edges;

  return (
    <Container id="blog">
      {posts.map((post) => (
        <BlogCard
          key={post.node.frontmatter.slug}
          thumbnail={
            post.node.frontmatter.featuredImage.childImageSharp.fluid.src ||
            `https://via.placeholder.com/185x278?text=Image+not+available`
          }
          alt={post.node.frontmatter.featuredImageAlt}
          postTitle={post.node.frontmatter.title}
          postDate={post.node.frontmatter.date}
          postDescription={post.node.frontmatter.excerpt}
          postPath={`/${post.node.frontmatter.slug}`}
          categories={post.node.frontmatter.category}
        />
      ))}
    </Container>
  );
};
