import React from 'react';
import styled from 'styled-components';
import { MdEmail, MdPhone } from 'react-icons/md';
import ContactOption from './ContactOption';
import ContactForm from './ContactForm';
import ContactBackgroundBlob from '../../assets/svg/ContactBackgroundBlob.svg';
import ContactCleaningSupplies from '../../assets/svg/ContactCleaningSupplies.svg';

const Container = styled.div`
  position: relative;
  margin: 2em 2em;

  .contactInfo {
    display: grid;
    grid-gap: 1em;
    grid-template-rows: 1fr 1fr;
  }

  .contactBackgroundBlob {
    position: absolute;
    margin: -12em -5em;
    z-index: -1;
    filter: drop-shadow(-3px 3px 2px rgba(0, 0, 0, 0.7));
    width: auto;
    height: 100%;
  }

  .cleaningSupplies {
    display: grid;
    place-items: center;
  }

  .cleaningSupplies > svg {
    width: 50%;
    height: auto;
  }

  @media (min-width: 1920px) {
    .contactBackgroundBlob {
      margin: -10em -5em;
      width: 65%;
      height: auto;
    }

    .contactInfo {
      justify-content: space-around;
      grid-template-columns: minmax(290px, 570px) minmax(290px, 675px);
      grid-template-rows: none;
    }
  }
`;

const SectionContact = () => (
  <Container id="contact" name="contact">
    <ContactBackgroundBlob className="contactBackgroundBlob" />
    <div className="contactInfo">
      <div>
        <p>
          Please contact us here to arrange a free quote or if you require
          anymore information about the services we provide.
        </p>
        <ContactOption
          icon={<MdEmail size="1em" />}
          title="Email"
          contact="louise@pristinepolish.co.uk"
        />
        <ContactOption
          icon={<MdPhone size="1em" />}
          title="Phone"
          contact="+44 (0)7536982061"
        />
        <div className="cleaningSupplies">
          <ContactCleaningSupplies />
        </div>
      </div>
      <ContactForm />
    </div>
  </Container>
);

export default SectionContact;
