import React from 'react';
import styled from 'styled-components';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { Link, animateScroll as scroll } from 'react-scroll';
import PristinePolishLogo from './PristinePolishLogo';

const StyledNavbar = styled(Navbar)`
  padding: 0 2em;
  background-color: var(--highlight-color);
  color: var(--text-color);

  button {
    max-width: 30px;
    min-width: 30px;
  }

  .navbar-nav > .nav-link {
    color: var(--text-color);
    font-size: 1em;
  }

  .navbar-nav > .nav-link.active {
    color: var(--text-color);
    font-weight: 700;
  }

  .navbar-nav > .nav-link:hover {
    cursor: pointer;
    color: var(--text-color);
  }

  .navbar-toggler {
    border: none;
    box-shadow: none;
    padding: 0;
    margin: 0;
  }

  .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28244, 244, 244, 1%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  }

  @media (min-width: 768px) {
    .navbar-nav > .nav-link {
      font-size: 0.5em;
    }
  }
`;

const Navigation = () => (
  <StyledNavbar expand="lg" fixed="top">
    <Navbar.Brand onClick={() => scroll.scrollToTop()}>
      <PristinePolishLogo />
    </Navbar.Brand>
    <Navbar.Toggle aria-controls="basic-navbar-nav" />
    <Navbar.Collapse id="navbar-nav" className="justify-content-end">
      <Nav>
        <Link
          className="nav-link"
          activeClass="active"
          to="services"
          spy
          smooth
          offset={-250}
          duration={500}
        >
          Services
        </Link>
        <Link
          className="nav-link"
          activeClass="active"
          to="about"
          spy
          smooth
          offset={-250}
          duration={500}
        >
          About
        </Link>
        <Link
          className="nav-link"
          activeClass="active"
          to="reviews"
          spy
          smooth
          offset={-250}
          duration={500}
        >
          Reviews
        </Link>
        <Link
          className="nav-link"
          activeClass="active"
          to="blog"
          spy
          smooth
          offset={-250}
          duration={500}
        >
          Blog
        </Link>
        <Link
          className="nav-link"
          activeClass="active"
          to="contact"
          spy
          smooth
          offset={-250}
          duration={500}
        >
          Contact
        </Link>
      </Nav>
    </Navbar.Collapse>
  </StyledNavbar>
);

export default Navigation;
