import React from 'react';
import styled from 'styled-components';
import { MdFormatQuote } from 'react-icons/md';

const Container = styled.div`
  background-color: var(--background-color);
  border-radius: 20px;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.35);
  padding: 0.75em;

  p {
    font-weight: normal;
    font-size: 0.75em;
    margin-bottom: 0.75em;
  }

  .centerContent {
    display: grid;
    place-items: center;
    text-align: center;
  }

  .clientName {
    font-weight: bold;
    font-size: 0.825em;
  }

  .underline {
    width: 4em;
    height: 0.3em;
    background-color: var(--underline-color);
    border-radius: 190px;
    margin-bottom: 0.3em;
  }
`;

const TestimonialCard = ({ testimonial, client, company }) => (
  <Container>
    <MdFormatQuote size="2.5em" />
    <div className="centerContent">
      <p>{testimonial}</p>
      <p className="clientName">{client}</p>
      <div className="underline" />
      <p>{company}</p>
    </div>
  </Container>
);

export default TestimonialCard;
