import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  margin: 2em 0;

  p {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0.2em;
  }

  .sectionHeaderUnderline {
    width: 100%;
    height: 0.2em;
    background-color: var(--underline-color);
    border-radius: 190px;
    margin-top: 0.2em;
  }
`;

const copyToClipboard = (e, contact) => {
  e.preventDefault();
  const temp = document.createElement('input');
  temp.type = 'text';
  temp.name = 'contactClipboard';
  temp.id = 'contactClipboard';
  temp.value = contact;
  temp.readonly = true;
  document.body.appendChild(temp);
  temp.select();
  document.execCommand('copy');
  document.body.removeChild(temp);
};

const ContactOption = ({ icon, title, contact }) => (
  <Container>
    <p>
      <span>
        {icon} {title}
      </span>{' '}
      <a href="#" onClick={(e) => copyToClipboard(e, contact)}>
        {contact}
      </a>
    </p>
    <div className="sectionHeaderUnderline" />
  </Container>
);

export default ContactOption;
