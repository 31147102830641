import React from 'react';
import styled from 'styled-components';
import TestimonialCard from '../testimonials/TestimonialCard';

const Container = styled.div`
  display: grid;
  grid-gap: 1em;
  grid-template-columns: repeat(1, minmax(290px, 1fr));
  margin: 2em 2em;

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, minmax(290px, 1fr));
  }
`;

const SectionTestimonials = () => (
  <Container id="reviews">
    <TestimonialCard
      testimonial="Pristine Polish provides a reliable and professional cleaning service carried out to a high standard. I have found the staff to be friendly and trustworthy which is essential for an office environment."
      client="Iain Donnachie - Director"
      company="The Structural Partnership Ltd"
    />

    <TestimonialCard
      testimonial="Pritine Polish has worked very hard in assisting me to keep my home spotless due to my mobility issues. I am very happy with their service and reliability and would recommend them to everyone."
      client="Mrs A Kelly"
      company="Home Owner"
    />
  </Container>
);

export default SectionTestimonials;
