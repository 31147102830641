import React from 'react';
import styled from 'styled-components';

const SectionHeaderStyles = styled.div`
  margin: 0 2em;

  h2 {
    font-size: 1.75em;
    text-transform: capitalize;
  }

  .sectionHeaderUnderline {
    width: 7.375em;
    height: 0.438em;
    background-color: var(--underline-color);
    border-radius: 190px;
  }

  @media (min-width: 768px) {
    h2 {
      font-size: 1.667em;
    }

    .sectionHeaderUnderline {
      width: 7.083em;
      height: 0.417em;
    }
  }

  @media (min-width: 1024px) {
    h2 {
      font-size: 2.222em;
    }

    .sectionHeaderUnderline {
      width: 9.259em;
      height: 0.444em;
    }
  }

  @media (min-width: 1200px) {
    h2 {
      font-size: 1.86em;
    }

    .sectionHeaderUnderline {
      width: 8.14em;
      height: 0.349em;
    }
  }
`;

const SectionHeader = ({ title }) => (
  <SectionHeaderStyles>
    <h2>{title}</h2>
    <div className="sectionHeaderUnderline" />
  </SectionHeaderStyles>
);

export default SectionHeader;
