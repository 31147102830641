import React from 'react';
import styled from 'styled-components';
import MeetLouiseCleaner from '../../assets/svg/MeetLouiseCleaner.svg';
import MeetLouiseImageBackground from '../../assets/svg/MeetLouiseImageBackground.svg';

const Container = styled.div`
  display: grid;
  grid-gap: 1em;
  grid-template-rows: repeat(auto-fit, minmax(290px, 1fr));
  margin: 2em 2em;

  .imageContainer {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .meetLouiseImageBackground {
    filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.7));
  }

  .meetLouiseImages {
    width: 70%;
    height: auto;
  }

  .meetLouiseImage {
    position: absolute;
    z-index: 1;
    width: 50%;
  }

  p {
    display: grid;
    place-content: center;
  }

  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
    grid-gap: 1em;

    .meetLouiseImages {
      width: 80%;
    }

    .meetLouiseImage {
      width: 25%;
    }
  }
`;

const SectionMeetLouise = () => (
  <Container id="about">
    <div className="imageContainer">
      <MeetLouiseImageBackground className="meetLouiseImageBackground meetLouiseImages" />
      <MeetLouiseCleaner className="meetLouiseImages meetLouiseImage" />
    </div>
    <p>
      Pristine Polish is an insured commercial and residential cleaning service
      based in Glasgow, Scotland. Created by Louise, who has 25 years of
      experience cleaning both residential and commercial properties. Louise is
      fully committed to providing a friendly and detailed service to meet all
      your needs no matter how big or small the job is. You can be sure she has
      the knowledge and experience to meet your every need.
    </p>
  </Container>
);

export default SectionMeetLouise;
