import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

const Container = styled.div`
  width: clamp(290px, 100%, 500px);
  display: flex;
  flex-direction: column;
  padding: 1rem;

  img {
    width: 100%;
    height: 12em;
  }

  img,
  div {
    border-radius: 20px;
  }

  p,
  a {
    font-size: 1em;
  }

  .blogCardDetails {
    background-color: var(--background-color);
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.35);
    width: 65%;
    padding: 0.5em;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    margin-top: -1em;
  }

  .blogCardTitle {
    font-family: 'IBM Plex Serif', serif;
    font-size: 1.5em;
    font-weight: 600;
  }

  @media (min-width: 768px) {
    img {
      height: 10em;
    }

    p,
    a {
      font-size: 0.75em;
    }

    .blogCardDetails {
      width: 75%;
    }

    .blogCardTitle {
      font-size: 1.25em;
    }
  }

  @media (min-width: 1024px) {
    p,
    a {
      font-size: 0.5em;
    }

    .blogCardTitle {
      font-size: 0.75em;
    }
  }

  @media (min-width: 1200px) {
    img {
      height: 7em;
    }
  }
`;

const BlogCard = ({ thumbnail, alt, postTitle, postDescription, postPath }) => (
  <Container>
    <img src={thumbnail} alt={alt} />
    <div className="blogCardDetails">
      <p className="blogCardTitle">{postTitle}</p>
      <p>{postDescription}</p>
      <Link to={postPath} className="noUnderline">
        Read Blog Post
      </Link>
    </div>
  </Container>
);

export default BlogCard;
