import React, { useState } from 'react';
import styled from 'styled-components';

const Styles = styled.form`
  background: #7e4bd8;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.35);
  border-radius: 20px;
  padding: 1em;
  display: grid;
  place-items: center;

  label,
  input,
  textarea {
    font-family: 'Open Sans', sans-serif;
    font-size: 0.875em;
    font-weight: 600;
    width: 100%;
  }

  label {
    font-size: 0.75em;
    font-weight: 400;
  }

  input,
  textarea {
    width: 100%;
    padding: 1em;
    margin-top: 0.3em;
    margin-bottom: 0.75em;
    color: var(--text-color);
    background: rgba(244, 244, 244, 0.3);
    border: 3px solid #5f37a9;
    box-sizing: border-box;
    border-radius: 20px;
    height: 3.5em;
  }

  textarea {
    height: 20em;
  }

  input:focus,
  textarea:focus {
    border: 3px solid #3a2167;
  }

  .hidden {
    display: none;
  }
`;

const ContactForm = () => {
  const [formState, setFormState] = useState({
    name: '',
    email: '',
    contactNumber: '',
    message: '',
  });

  const handleFormChange = (e) => {
    setFormState({
      ...formState,
      [e.target.name]: e.target.value,
    });
  };

  const encode = (data) =>
    Object.keys(data)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`
      )
      .join('&');

  const handleSubmit = (e) => {
    e.preventDefault();

    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({ 'form-name': 'contact', ...formState }),
    })
      .then(() => {
        console.log('form submitted');
        setFormState({
          name: '',
          email: '',
          contactNumber: '',
          message: '',
        });
        document.querySelector(`form[name='contact']`).reset();
      })
      .catch((error) => console.log(error));
  };

  return (
    <Styles
      onSubmit={handleSubmit}
      name="contact"
      method="POST"
      netlify-honeypot="bot-field"
      data-netlify="true"
    >
      <p className="hidden">
        <label htmlFor="bot-field">
          Don’t fill this out if you're human: <input name="bot-field" />
        </label>
      </p>
      <label htmlFor="name">
        Contact Name
        <input
          type="text"
          name="name"
          id="name"
          value={formState.name}
          onChange={handleFormChange}
          required
        />
      </label>
      <label htmlFor="email">
        Email
        <input
          type="email"
          name="email"
          id="email"
          value={formState.email}
          onChange={handleFormChange}
          required
        />
      </label>
      <label htmlFor="contactNumber">
        Phone Number
        <input
          type="tel"
          name="contactNumber"
          id="contactNumber"
          value={formState.contactNumber}
          onChange={handleFormChange}
          pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
        />
      </label>
      <label htmlFor="message">
        Message
        <textarea
          name="message"
          id="message"
          rows="5"
          value={formState.message}
          onChange={handleFormChange}
          required
        />
      </label>
      <button type="submit">Send</button>
    </Styles>
  );
};

export default ContactForm;
