import React from 'react';
import styled from 'styled-components';
import Header from '../components/home/Header';
import HeaderBackground from '../assets/svg/header.svg';
import SectionHeader from '../components/common/SectionHeader';
import SectionServices from '../components/home/SectionServices';
import SectionMeetLouise from '../components/home/SectionMeetLouise';
import SectionTestimonials from '../components/home/SectionTestimonials';
import SectionBlog from '../components/home/SectionBlog.js';
import SectionContact from '../components/common/SectionContact';
import Footer from '../components/common/Footer';
import Navigation from '../components/common/Navigation';
import SEO from '../components/seo';
import Thumbnail from '../assets/images/Header.jpg';

const Container = styled.div`
  display: grid;
  grid-template-rows: auto 1fr auto;
`;

const index = () => (
  <>
    <SEO title="Home" imageSRC={Thumbnail} />
    <div className="backgroundContainer">
      <HeaderBackground className="background" />
    </div>
    <Navigation />
    <Container>
      <Header />
      <main>
        <SectionHeader title="services" />
        <SectionServices />
        <SectionHeader title="meet louise" />
        <SectionMeetLouise />
        <SectionHeader
          title="What my clients
are saying"
        />
        <SectionTestimonials />
        <SectionHeader title="Cleaning Advice" />
        <SectionBlog />
        <SectionHeader title="Get in touch" />
        <SectionContact />
      </main>
      <Footer />
    </Container>
  </>
);

export default index;
