import React from 'react';
import styled from 'styled-components';
import ServicesCard from '../services/ServicesCard';
import PersonCleaningBathroomSink from '../../assets/images/PersonCleaningBathroomSink.jpg';
import PersonCleaningWorktop from '../../assets/images/PersonCleaningWorktop.jpg';
import PersonSweepingFloor from '../../assets/images/PersonSweepingFloor.jpg';
import PersonWashingMirror from '../../assets/images/PersonWashingMirror.jpg';

const Container = styled.div`
  display: grid;
  grid-gap: 2em;
  grid-template-columns: repeat(1, minmax(290px, 0.75fr));
  place-content: center;
  margin: 2em 2em;

  @media (min-width: 1200px) {
    grid-template-columns: repeat(2, minmax(500px, 1fr));
  }
`;

const SectionServices = () => (
  <Container id="services" name="services">
    <ServicesCard
      image={PersonSweepingFloor}
      imageAlt="Person sweeping floor by cottonbro from Pexels"
      title="Domestic Regular Clean"
    >
      <p>
        Here at Pristine Polish we know the benefits and imports of having a
        clean and tidy home. That’s why our residential cleaning services can
        help give you peace of mind. Our service can be tailored around your
        busy schedule. Whether you need a one time visit for a special occasion.
        Or regular visits to ensure you and your family are taken care of.
      </p>
      <ul>
        <li>Weekly Service - Starting from £12 per hour</li>
        <li>One-off Service - Starting from £18 per hour</li>
      </ul>
      <table>
        <thead>
          <tr>
            <th>Area</th>
            <th>Standard Services</th>
            <th>Additional Services (Monthly)</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <p>All areas</p>
            </td>
            <td>
              <p>Hoover and mop all floors and stairs</p>
              <p>Dusting all furniture, shelves, light and decor fixings</p>
              <p>Clean window sills</p>
              <p>Polish mirrors, chrome and glass surfaces</p>
              <p>Make beds & or change bedding</p>
              <p>Empty Bins</p>
            </td>
            <td>
              <p>
                Move and clean under furniture* (large furniture must be moved
                by owner)
              </p>
              <p>Leather sofa cleaning</p>
              <p>Wipe down doors and on top of doors</p>
              <p>Dust & wash all skirting boards</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Kitchen</p>
            </td>
            <td>
              <p>Clean worktops</p>
              <p>Hob, sink, tiles and splashboards</p>
              <p>Damp wipe cupboard fronts, table and chairs</p>
              <p>Clean outside of appliances</p>
            </td>
            <td>
              <p>Microwave Inside</p>
              <p>Dishwasher inside</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Bathroom</p>
            </td>
            <td>
              <p>Scrub toilets including bowl, seat, lid, tank and base</p>
              <p>Clean showers, baths and sinks inside and out</p>
            </td>
            <td>
              <p>Clean walls and tiles</p>
              <p>Bathroom cupboards inside</p>
            </td>
          </tr>
        </tbody>
      </table>

      <p>
        The following services can also be provided for an additional charge:
      </p>
      <ul>
        <li>
          Clean inside and outside of windows. (Upper floor windows only if
          windows open inwards)
        </li>
        <li>Dog walking</li>
        <li>Laundry service - Machine wash clothes</li>
        <li>Fridge and freezer inside* (if defrosted in advance)</li>
        <li>Oven & grill inside</li>
      </ul>
    </ServicesCard>
    <ServicesCard
      image={PersonWashingMirror}
      imageAlt="Person washing a mirror by Karolina Grabowska from Pexels"
      title="Domestic Deep Clean"
    >
      <p>
        Our one-off domestic deep clean service includes all services offered in
        our regular cleaning service plus more. This service ensures every
        corner of your home is free of dust, dirt and build-up from top to
        bottom. Our deep clean service can be extremely useful after large
        decorating or home renovation works, as well as moving in or out of a
        property.
      </p>
      <p>Staring from £70</p>
      <table>
        <thead>
          <tr>
            <th>Area</th>
            <th>Standard Services</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <p>All areas</p>
            </td>
            <td>
              <p>Vacuum & wash all floors including stairs</p>
              <p>Dusting all furniture, shelves, light and decor fixings</p>
              <p>Clean inside windows</p>
              <p>Clean window sills</p>
              <p>Polish mirrors, chrome and glass surfaces</p>
              <p>Make beds & or change bedding</p>
              <p>
                Move and clean under furniture* (large furniture must be moved
                by owner)
              </p>
              <p>Dust and wash skirting boards</p>
              <p>Wipe down doors and on top of doors</p>
              <p>Empty Bins</p>
              <p>
                Windows outside*( Upper floors only if windows open inwards)
              </p>
              <p>Cleaning of other paintwork</p>
              <p>Steam cleaning</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Kitchen</p>
            </td>
            <td>
              <p>Clean worktops</p>
              <p>Hob, sink, tiles and splashboards</p>
              <p>Damp wipe cupboard fronts, table and chairs</p>
              <p>
                Clean inside & outside of appliances*(Freezer inside if
                defrosted in advance by client)
              </p>
              <p>Clean Cupboards inside</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Bathroom</p>
            </td>
            <td>
              <p>Scrub toilet including bowl, seat, lid, tank and base</p>
              <p>Shower, bath and sink inside and out</p>
              <p>Clean walls and tiles</p>
              <p>Bathroom cupboards inside</p>
            </td>
          </tr>
        </tbody>
      </table>

      <p>
        The following services can also be provided for an additional charge:
      </p>
      <ul>
        <li>Laundry - Machine wash clothes</li>
        <li>Dog Walking</li>
        <li>Carpet Cleaning - Must provide Carpet Shampooer</li>
      </ul>
    </ServicesCard>
    <ServicesCard
      image={PersonCleaningWorktop}
      imageAlt="Person cleaning worktop by Anton Uniqueton from Pexels"
      title="Commercial Regular Clean"
    >
      <p>
        Pristine Polish provides an experienced and wide selection of commercial
        cleaning services. To businesses across Glasgow and surrounding areas.
        We can ensure your business, whether a small or large office, shop or
        restaurant. Is cleaned to the highest standard. While also being
        flexible to work around the times best for you and your business.
      </p>
      <ul>
        <li>Weekly Service - Starting from £15</li>
        <li>One-off Service - Starting from £22</li>
      </ul>
      <table>
        <thead>
          <tr>
            <th>Area</th>
            <th>Standard Services</th>
            <th>Additional Services (Monthly)</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <p>All areas</p>
            </td>
            <td>
              <p>Vacuum floors</p>
              <p>Wash floors</p>
              <p>Empty Bins</p>
            </td>
            <td>
              <p>Clean skirtings & window sills</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Offices / Meeting Rooms</p>
            </td>
            <td>
              <p>Clean desks & cabinets</p>
              <p>Clean door handles</p>
            </td>
            <td>
              <p>Dust backs & bottoms of chairs</p>
              <p>Dust picture frames</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Kitchen</p>
            </td>
            <td>
              <p>Clean tops of surfaces & splashboards</p>
              <p>Wipe clean cupboard doors </p>
              <p>Clean inside & outside of microwave</p>
            </td>
            <td>
              <p>Clean inside of fridge</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Bathrooms</p>
            </td>
            <td>
              <p>Clean sinks inside & out</p>
              <p>Scrub toilet including bowl, seat, lid, tank and base</p>
              <p>Clean mirrors</p>
              <p>Clean soap dispenser</p>
              <p>Replenish toilet paper, hand wash & paper towels</p>
              <p>Wipe down cubicle doors</p>
              <p>Wash skirtings</p>
            </td>
            <td>
              <p>Clean shower room</p>
            </td>
          </tr>
        </tbody>
      </table>

      <p>
        The following services can also be provided for an additional charge:
      </p>
      <ul>
        <li>
          Collect and wash tableware and glasses/cups - Dishwasher or by hand
        </li>
        <li>Empty dishwasher</li>
        <li>Take home and machine wash dish towels</li>
      </ul>
    </ServicesCard>
    <ServicesCard
      image={PersonCleaningBathroomSink}
      imageAlt="Person cleaning bathroom sink by Karolina Grabowska from Pexels"
      title="Commercial Deep Clean"
    >
      <p>
        Our one-off commercial deep clean service includes all services we offer
        in our regular cleaning service and more. This service ensures every
        corner of your place of work is cleaned thoroughly to remove all dust,
        dirt and build up. This service can be especially handy for returning to
        your place of work after a long time away.
      </p>
      <p>Staring from £80</p>
      <table>
        <thead>
          <tr>
            <th>Area</th>
            <th>Standard Services</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <p>All areas</p>
            </td>
            <td>
              <p>Vacuum floors</p>
              <p>Wash floors</p>
              <p>Empty Bins</p>
              <p>Clean door handles</p>
              <p>Clean Skirtings & window sills</p>
              <p>Clean doors</p>
              <p>Clean Light switches & sockets</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Offices / Meeting Rooms</p>
            </td>
            <td>
              <p>Clean desks & cabinets</p>
              <p>Clean & vacuum chairs</p>
              <p>Clean tables</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Kitchen</p>
            </td>
            <td>
              <p>Clean tops of surfaces & splashboards</p>
              <p>Wipe clean cupboard doors</p>
              <p>Clean inside & outside of microwave</p>
              <p>Clean inside bins</p>
              <p>Clean inside cupboards</p>
              <p>Clean inside of Fridge</p>
              <p>Clean dishwasher</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Bathrooms</p>
            </td>
            <td>
              <p>Clean sinks inside & out</p>
              <p>Scrub toilet including bowl, seat, lid, tank and base</p>
              <p>Clean mirrors</p>
              <p>Clean soap dispenser</p>
              <p>Replenish toilet paper, hand wash & paper towels</p>
              <p>Wipe down cubicle doors</p>
              <p>Wash skirtings</p>
            </td>
          </tr>
        </tbody>
      </table>

      <p>
        The following services can also be provided for an additional charge:
      </p>
      <ul>
        <li>Carpet Cleaning - Must provide Carpet Shampooer</li>
      </ul>
    </ServicesCard>
  </Container>
);

export default SectionServices;
