import React from 'react';
import styled from 'styled-components';
import { scroller } from 'react-scroll';
import HeaderCleaner from '../../assets/svg/headerCleaner.svg';

const HeaderContainer = styled.header`
  padding: 5em 2em;
  height: 100vh;
  display: grid;
  place-items: center;

  .headerContent {
    display: grid;
    place-items: center;
  }

  title {
    margin-bottom: 0.5em;
    display: block;
  }

  p {
    margin-bottom: 3em;
  }

  .buttonContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: space-between;
  }

  .headerImage {
    margin-top: 3em;
    min-width: 75%;
    height: auto;
  }

  @media (min-width: 768px) {
    padding: 3em 2em;

    .headerContent {
      grid-template-columns: minmax(440px, 750px) minmax(216px, 517px);
      grid-gap: 2em;
      justify-content: space-around;
    }

    .headerImage {
      margin: 0;
      min-width: 100%;
    }
  }
`;

const Header = () => (
  <HeaderContainer>
    <div className="headerContent">
      <div className="headerMain">
        <title>High quality commercial and residential cleaning</title>
        <p>
          A Glasgow based cleaning service delivering a high-quality service to
          brighten up and freshen your home or business.
        </p>
        <div className="buttonContainer">
          <button
            onClick={() =>
              scroller.scrollTo('services', {
                spy: true,
                smooth: true,
                offset: -250,
                duration: 500,
              })
            }
          >
            Services
          </button>
          <button
            className="ctaSecondary"
            onClick={() =>
              scroller.scrollTo('contact', {
                spy: true,
                smooth: true,
                offset: -250,
                duration: 500,
              })
            }
          >
            Contact
          </button>
        </div>
      </div>
      <HeaderCleaner className="headerImage" />
    </div>
  </HeaderContainer>
);

export default Header;
